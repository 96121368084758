import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import history from "../history";
import { useAuth0 } from "../react-auth0-spa";
import { useAuth } from "../context/auth";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../helpers/remote";

import DefaultLayout from "../layouts/DefaultLayout";
import { useClearCache } from "react-clear-cache";
import AgentClassService from "../helpers/AgentClassService"
import { getUrlParameter } from "../helpers/formatFunctions";

const Auth0Wrapper = ({ Component, path, isAuth, ...rest }) => {
  const { setShowAccessDenied } = useAuth();

  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  const [hideLogout, setHideLogout] = useState(false);

  useEffect(() => {
    if (window.SSO_PROVIDER === "auth0") {
      if (loading || isAuthenticated) {
        return;
      }
      const fn = async () => {
        let allParams = getUrlParameter(rest.location.search.split("?")[1]);
        if (
          allParams &&
          allParams.error &&
          (allParams.error === "unauthorized" ||
            allParams.error === "access_denied")
        ) {
          setHideLogout(true);
          setShowAccessDenied(true);
        } else {
          const isThorughPopup = localStorage.getItem("agentThroughAPI");
          localStorage.clear();
          AgentClassService.clear();
          if ( isThorughPopup ) {
            localStorage.setItem("agentThroughAPI", isThorughPopup);
          }
          await loginWithRedirect({
            appState: {
              targetUrl: `${window.location.pathname}${rest.location.search}`,
            },
          });
        }
      };
      fn();
    }
  }, [loading, isAuthenticated, loginWithRedirect, window.location.pathname]);

  return (
    <Route
      history={history}
      {...rest}
      render={(matchProps) =>
        isAuthenticated === true ? (
          <DefaultLayout>
            <Component {...matchProps} />
          </DefaultLayout>
        ) : (
          <DefaultLayout hideLogout={hideLogout} />
        )
      }
    />
  );
};

const OAuthWrapper = ({ Component, path, isAuth, ...rest }) => {
  const makeApiCall = useFetch();

  const { setShowAccessDenied, setAuthTokens, authTokens } = useAuth();

  const [hideLogout, setHideLogout] = useState(false);

  useEffect(() => {
    let allParams = getUrlParameter(rest.location.search.split("?")[1]);
    if (!localStorage.getItem("originalPath")) {
      localStorage.setItem(
        "originalPath",
        `${window.location.pathname}${window.location.search}`
      );
    }

    if (
      allParams &&
      allParams.error &&
      (allParams.error === "unauthorized" ||
        allParams.error === "access_denied")
    ) {
      setHideLogout(true);
      setShowAccessDenied(true);
    } else if (allParams && allParams.code) {
      makeApiCall
        .request(window.SSO_DOMAIN + "/oauth/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            client_id: window.SSO_CLIENTID,
            grant_type: "authorization_code",
            code: allParams.code,
            redirect_uri: window.location.origin,
          }),
        })
        .then((json) => {
          setAuthTokens(json);
          let originalPath = localStorage.getItem("originalPath");
          if(!localStorage.getItem("originalPath")?.includes("?agent")) {
            localStorage.removeItem("originalPath");
          }
          history.push(originalPath);
        });
    } else {
      let loginPopup = allParams.login ? allParams.login : "";
      let url =
        window.SSO_DOMAIN +
        "/authorize?scope=openid%20profile%20email&response_type=code&client_id=" +
        window.SSO_CLIENTID +
        "&redirect_uri=" +
        window.location.origin +
        "&nonce=" +
        uuidv4() +
        "&state=" +
        uuidv4() +
        "&agent=" +
        allParams.agent +
        "&class=" +
        allParams?.class +
        "&through=" +
        allParams.through +
        "&login=" +
        loginPopup;

      window.location.href = url;
    }
  }, []);

  return (
    <Route
      history={history}
      {...rest}
      render={(matchProps) =>
        authTokens ? (
          <DefaultLayout>
            <Component {...matchProps} />
          </DefaultLayout>
        ) : (
          <DefaultLayout hideLogout={hideLogout} />
        )
      }
    />
  );
};

function PrivateRoute({ component: Component, path, isAuth, ...rest }) {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;
  if (!isLatestVersion && !isEdge && !isIE) {
    // emptyCacheStorage();
  }
  if (window.SSO_PROVIDER === "auth0") {
    return <Auth0Wrapper Component={Component} path={path} {...rest} />;
  } else if (window.SSO_PROVIDER === "oneauth") {
    return (
      <OAuthWrapper
        Component={Component}
        path={path}
        {...rest}
        isAuth={isAuth}
      />
    );
  }
}

export default PrivateRoute;
