import React, { useEffect, useState } from "react";

import IconLabelButton from "../IconLabelButton";
import Checkbox from "../Checkbox";
import TextInput from "../TextInput";
import SuggestionButton from "../SuggestionButton";

import { useTranslation } from "react-i18next";

const CorrectAnswerRecord = ({
  questionId,
  questionType,
  question,
  answers,
  submittedAnswers,
  questionScore,
  userScore,
  answerKeySettings,
  onReviewRequest,
  resetCheckboxes,
  enableLearning,
  showAllScores,
  subquestionsData,
  questionNumber,
  conditions,
  onConditionButtonClick,
  displayedQuestionsId,
  submissionData,
  answerFeedback,
  questionScoredBy,
  item,
  idealAnswer,
}) => {
  const { t } = useTranslation("translation");
  const [toBeReviewed, setToBeReviewed] = useState(false);
  const [reviewComment, setReviewComment] = useState("");
  const [subReviewComment, setSubReviewComment] = useState({});
  const [questionOptions, setQuestionOptions] = useState(
    item && item.options ? [...item.options] : []
  );
  useEffect(() => {
    setToBeReviewed(false);
  }, [resetCheckboxes]);

  const handleReviewRequestToggle = (checked) => {
    setToBeReviewed(checked);
    if (checked) {
      onReviewRequest(questionId, "add", reviewComment);
    } else {
      onReviewRequest(questionId, "remove", reviewComment);
    }
  };

  const handleSubQueReviewRequestToggle = (checked, questionId) => {
    let temp = { ...subReviewComment };
    temp[questionId] = {
      ...temp[questionId],
      checkboxState: checked,
    };
    setSubReviewComment(temp);
    let comment =
      subReviewComment[questionId] && subReviewComment[questionId].comment
        ? subReviewComment[questionId].comment
        : "";
    if (checked) {
      onReviewRequest(questionId, "add", comment);
    } else {
      onReviewRequest(questionId, "remove", comment);
    }
  };

  const onFieldChange = (value, label) => {
    if (label === "reviewComment") {
      setReviewComment(value);
      onReviewRequest(questionId, "change", value);
    }
  };

  const onSubReviewFieldChange = (value, id) => {
    let temp = { ...subReviewComment };
    temp[id] = {
      ...temp[id],
      comment: value,
    };
    setSubReviewComment(temp);
    onReviewRequest(id, "change", value);
  };

  const getTableDisplay = (data, noHeader, answerKeySettings) => {
    let temp =
      data && typeof data === "string" && JSON.parse(data)
        ? JSON.parse(data)
        : data;

    // if (answerKeySettings && answerKeySettings.enable_column_header) {
    //   temp.map((row, index) =>
    //     row.unshift(answerKeySettings.column_headers[index])
    //   );
    // }

    return (
      <table>
        {!noHeader && (
          <thead>
            <tr>
              {answerKeySettings &&
                answerKeySettings.headers &&
                answerKeySettings.headers.map((column) => <th>{column}</th>)}
            </tr>
          </thead>
        )}
        <tbody>
          {temp &&
            temp.map((row, index) => (
              <tr>
                {answerKeySettings && answerKeySettings.column_headers && (
                  <th>{answerKeySettings.column_headers[index]}</th>
                )}
                {row.map((column) => (
                  <td>{column}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getMatchDisplay = (data, referenceData) => {
    return (
      <table>
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column) => <th>{column}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr>
              <td>{row.term}</td>
              <td>{row.definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const getBulletDisplay = (data) => {
    return (
      <ul>
        {data.map((item) => (
          <li>{item.text}</li>
        ))}
      </ul>
    );
  };

  const divideArray = (data, controlColumnCount) => {
    let temp = [...data];
    const result = new Array(Math.ceil(temp.length / controlColumnCount))
      .fill()
      .map((_) => temp.splice(0, controlColumnCount));
    return result;
  };

  const getNewTableDisplay = (data, answerKeySettings) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {divideArray(data, answerKeySettings.column_count).map(
            (row, index) => (
              <tr>
                <th className="quiz-table-header-cell">
                  {"column_headers" in answerKeySettings
                    ? answerKeySettings.column_headers[index]
                    : ""}
                </th>
                {row.map((column) => (
                  <td className="quiz-table-answer-cell">{column.text}</td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  };

  const getSubAnswerDisplay = (data, subQuestionNumber) => {
    return (
      <React.Fragment>
        <div className="half-clearfix" />
        <div className="question-row side-padding">
          <h3 className="page-main-heading">
            {t("Question")} {questionNumber}.{subQuestionNumber}{" "}
            {(data.questionScore !== undefined ||
              data.userScore !== undefined) &&
              (data.questionScore !== 0 ||
                data.questionScore !== "0" ||
                data.questionScore !== "0.00") &&
              showAllScores !== false &&
              data.questionType !== "group" && (
                <span>
                  ({t("Score:")}{" "}
                  {submissionData &&
                  submissionData.result_settings &&
                  submissionData.result_settings.show_scores_in_percentage
                    ? data.userScore === "-"
                      ? t("Not available")
                      : Math.floor(
                          (data.userScore / data.questionScore) * 100
                        ) + "%"
                    : data.userScore === "-"
                    ? t("Not available")
                    : data.userScore + "/" + data.questionScore}
                  )
                </span>
              )}
          </h3>
          <div
            className="answer-table-question answer-table-subquestion"
            dangerouslySetInnerHTML={{ __html: data.question }}
          />
        </div>
        <div className="half-clearfix" />
        {data.questionType !== "group" && (
          <div className="answer-row flex-container">
            {submissionData &&
              submissionData.result_settings &&
              submissionData.result_settings.show_answers && (
                // submissionData.result_settings.show_answers !==
                //   null
                <div className="flex-one side-padding">
                  <h3 className="page-subheading">
                    {questionType === "text" || data.questionType === "essay"
                      ? t("Feedback")
                      : t("Recommended Answer")}
                  </h3>
                  {data.questionType === "description" &&
                    data.answers.map((answer, localIndex) => (
                      <div className="answer-table-question" key={localIndex}>
                        {getTableDisplay(
                          "text" in answer ? answer.text : "",
                          data.questionType === "description",
                          data.answerKeySettings
                        )}
                      </div>
                    ))}
                  {data.questionType === "table" &&
                    data.answers.map((answer, localIndex) => (
                      <div className="answer-table-question" key={localIndex}>
                        {getNewTableDisplay(
                          "bullets" in answer ? answer.bullets : [],
                          data.answerKeySettings
                        )}
                      </div>
                    ))}
                  {data.questionType === "matching" &&
                    data.answers.map((answer, localIndex) => (
                      <div className="answer-table-question" key={localIndex}>
                        {getMatchDisplay(
                          "matches" in answer ? answer.matches : []
                        )}
                      </div>
                    ))}
                  {data.questionType === "newbullet" &&
                    data.answers.map((answer, localIndex) => (
                      <div className="answer-table-question" key={localIndex}>
                        {getBulletDisplay(
                          "bullets" in answer ? answer.bullets : []
                        )}
                      </div>
                    ))}
                  {((data.questionType === "text" &&
                    data.questionScoredBy === "essay_grader") ||
                    data.questionType === "essay") && (
                    <p className="essay-feed-back">{data.answerFeedback}</p>
                  )}
                  {data.questionType !== "table" &&
                    data.questionType !== "description" &&
                    data.questionType !== "matching" &&
                    data.questionType !== "newbullet" &&
                    data.answers.map((answer, localIndex) => (
                      <div className="answer-table-question" key={localIndex}>
                        {"html" in answer ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: answer.html }}
                          />
                        ) : "text" in answer ? (
                          answer.text
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                </div>
              )}
            <div className="flex-one side-padding">
              <h3 className="page-subheading">{t("Your Answer")}</h3>
              {(data.questionType === "table" ||
                data.questionType === "description") &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getTableDisplay(
                      "text" in answer ? answer.text : "",
                      data.questionType === "description",
                      data.answerKeySettings
                    )}
                  </div>
                ))}
              {data.questionType === "matching" &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getMatchDisplay(
                      "matches" in answer ? answer.matches : [],
                      data.answers[localIndex]
                    )}
                  </div>
                ))}
              {(data.questionType === "newbullet" ||
                data.questionType === "bullet") &&
                data.submittedAnswers.map((answer, localIndex) => {
                  const submittedAnswerList =
                    "text" in answer
                      ? answer.text.split("\n").map((item) => {
                          return { text: item.replace("- ", "").trim() };
                        })
                      : [];
                  return (
                    <div className="answer-table-question" key={localIndex}>
                      {getBulletDisplay(submittedAnswerList)}
                    </div>
                  );
                })}
              {data.questionType !== "table" &&
                data.questionType !== "description" &&
                data.questionType !== "matching" &&
                data.questionType !== "newbullet" &&
                data.questionType !== "bullet" &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {"html" in answer ? (
                      <div dangerouslySetInnerHTML={{ __html: answer.html }} />
                    ) : "text" in answer ? (
                      answer.text
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
        {(data.questionScore !== undefined || data.userScore !== undefined) &&
          (data.questionScore !== 0 ||
            data.questionScore !== "0" ||
            data.questionScore !== "0.00") &&
          showAllScores !== false &&
          data.questionType !== "group" && (
            <>
              <div className="half-clearfix" />
              <div className="flex-container">
                <div className="flex-one side-padding">
                  <div className="left-align">
                    {onReviewRequest  && (
                      <>
                        <Checkbox
                          addExtraId
                          options={{
                            enumOptions: [
                              {
                                value:
                                  subReviewComment[data.questionId] &&
                                  subReviewComment[data.questionId]
                                    .checkboxState
                                    ? subReviewComment[data.questionId]
                                        .checkboxState
                                    : false,
                                label: t("Select to send for review"),
                              },
                            ],
                          }}
                          checked={
                            subReviewComment[data.questionId] &&
                            subReviewComment[data.questionId].checkboxState
                              ? subReviewComment[data.questionId].checkboxState
                              : false
                          }
                          onChange={(checked) =>
                            handleSubQueReviewRequestToggle(
                              checked,
                              data.questionId
                            )
                          }
                        />
                        <div className="half-clearfix" />
                        <TextInput
                          inputType="text"
                          inputClass="review-comment"
                          name="reviewComment"
                          placeholder={t("Add comment here (optional)")}
                          value={
                            subReviewComment[data.questionId] &&
                            subReviewComment[data.questionId].comment
                              ? subReviewComment[data.questionId].comment
                              : ""
                          }
                          inputChange={(event) =>
                            onSubReviewFieldChange(
                              event.target.value,
                              data.questionId
                            )
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        {conditions &&
          conditions.length > 0 &&
          submissionData?.result_settings?.show_answers &&
          conditions
            .filter((show) => show.question_id === data.questionId)
            .map((show) => (
              <div className="question-conditions-container">
                {show.message && (
                  <div className="condition-message">
                    <div className="half-clearfix" />
                    <div dangerouslySetInnerHTML={{ __html: show.message }} />
                  </div>
                )}
                {show.buttons && (
                  <div className="condition-buttons">
                    {show.buttons.map((button, index) => (
                      <SuggestionButton
                        key={index.toString()}
                        buttonLabel={button.label}
                        buttonAction={() =>
                          onConditionButtonClick(
                            button,
                            "quiz",
                            displayedQuestionsId,
                            submissionData
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
        <div className="half-clearfix" />
      </React.Fragment>
    );
  };

  return (
    <div className="correct-answer-record-container">
      <div className="question-row side-padding">
        <h3 className="page-main-heading">
          {t("Question")} {questionNumber}{" "}
          {(questionScore !== undefined || userScore !== undefined) &&
            (questionScore !== 0 ||
              questionScore !== "0" ||
              questionScore !== "0.00") &&
            showAllScores !== false &&
            questionType !== "group" &&
            submissionData &&
            submissionData.result_settings &&
            (submissionData.result_settings.show_scores ||
              submissionData.result_settings.show_scores === null) && (
              <span>
                ({t("Score:")}{" "}
                {submissionData &&
                submissionData.result_settings &&
                submissionData.result_settings.show_scores_in_percentage &&
                submissionData.result_settings.show_scores_in_percentage !==
                  null
                  ? userScore === "-"
                    ? t("Not available")
                    : Math.floor((userScore / questionScore) * 100) + "%"
                  : userScore === "-"
                  ? t("Not available")
                  : userScore + "/ " + questionScore}
                )
              </span>
            )}
        </h3>
        <div
          className="answer-table-question question"
          dangerouslySetInnerHTML={{ __html: question }}
        />
      </div>
      <div className="half-clearfix" />
      {questionType !== "group" && (
        <div className="answer-row flex-container">
          {submissionData &&
            submissionData.result_settings &&
            submissionData.result_settings.show_answers && (
              // submissionData.result_settings.show_answers !==
              //   null
              <div className="flex-one side-padding">
                <h3 className="page-subheading">
                  {(questionType === "text" &&
                    questionScoredBy === "essay_grader") ||
                  questionType === "essay"
                    ? t("Feedback")
                    : t("Recommended Answer")}
                </h3>

                {questionType === "description" &&
                  answers.map((answer, localIndex) => (
                    <div className="answer-table-question" key={localIndex}>
                      {getTableDisplay(
                        "text" in answer ? answer.text : "",
                        questionType === "description",
                        answerKeySettings
                      )}
                    </div>
                  ))}
                {questionType === "table" &&
                  answers.map((answer, localIndex) => (
                    <div className="answer-table-question" key={localIndex}>
                      {getNewTableDisplay(
                        "bullets" in answer ? answer.bullets : [],
                        answerKeySettings
                      )}
                    </div>
                  ))}
                {questionType === "matching" &&
                  answers.map((answer, localIndex) => (
                    <div className="answer-table-question" key={localIndex}>
                      {getMatchDisplay(
                        "matches" in answer ? answer.matches : []
                      )}
                    </div>
                  ))}
                {questionType === "newbullet" &&
                  answers.map((answer, localIndex) => (
                    <div className="answer-table-question" key={localIndex}>
                      {getBulletDisplay(
                        "bullets" in answer ? answer.bullets : []
                      )}
                    </div>
                  ))}
                {questionType === "bullet" &&
                  answers.map((answer, localIndex) => (
                    <>
                      {"html" in answer ? (
                        <div
                          className="answer-table-question bullet"
                          key={localIndex}
                          dangerouslySetInnerHTML={{ __html: answer.html }}
                        />
                      ) : "text" in answer ? (
                        answer.text
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                {((questionType === "text" &&
                  questionScoredBy === "essay_grader") ||
                  questionType === "essay") && (
                  <p className="essay-feed-back">{answerFeedback ? answerFeedback.replace(/```/g, "") : ""}</p>
                )}
                {questionType !== "table" &&
                  questionType === "text" &&
                  questionScoredBy !== "essay_grader" &&
                  questionType !== "description" &&
                  questionType !== "matching" &&
                  questionType !== "newbullet" &&
                  answers.map((answer, localIndex) => (
                    <div className="answer-table-question" key={localIndex}>
                      {"html" in answer ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: answer.html }}
                        />
                      ) : "text" in answer ? (
                        answer.text
                      ) : (
                        ""
                      )}
                    </div>
                  ))}

                {(questionType === "radio" || questionType === "checkbox") && (
                  <div
                    className="display-options"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {questionOptions
                      .filter((option) => option.is_correct)
                      .map((option, index) => (
                        <div
                          key={option.id + "_DO_" + index}
                          className="option-display-field"
                        >
                          {/* {questionType === "radio" && (
                        <span className="radio-circle" />
                      )}
                      {questionType === "checkbox" && (
                        <span className="checkbox-square" />
                      )} */}
                          <span
                            dangerouslySetInnerHTML={{ __html: option.text }}
                          ></span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
          <div className="flex-one side-padding">
            <h3 className="page-subheading">{t("Your Answer")}</h3>
            {(questionType === "table" || questionType === "description") &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getTableDisplay(
                    "text" in answer ? answer.text : "",
                    questionType === "description",
                    answerKeySettings
                  )}
                </div>
              ))}
            {questionType === "matching" &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getMatchDisplay(
                    "matches" in answer ? answer.matches : [],
                    answers[localIndex]
                  )}
                </div>
              ))}
            {(questionType === "newbullet" || questionType === "bullet") &&
              submittedAnswers.map((answer, localIndex) => {
                const submittedAnswerList =
                  "text" in answer
                    ? answer.text.split("\n").map((item) => {
                        return { text: item.replace("- ", "").trim() };
                      })
                    : [];
                return (
                  <div className="answer-table-question" key={localIndex}>
                    {getBulletDisplay(submittedAnswerList)}
                  </div>
                );
              })}
            {questionType !== "table" &&
              questionType !== "description" &&
              questionType !== "matching" &&
              questionType !== "newbullet" &&
              questionType !== "bullet" &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {"html" in answer ? (
                    <div dangerouslySetInnerHTML={{ __html: answer.html }} />
                  ) : "text" in answer ? (
                    answer.text
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
      {questionType === "essay" && idealAnswer?.length > 0 && (
        <div className="answer-table-question question text-left p-3 text-[13px]">
          <div>
            <b>{t("Ideal Answer")}</b>
          </div>
          <div>
            <p dangerouslySetInnerHTML={{ __html: idealAnswer }} />
          </div>
        </div>
      )}
      {(questionScore !== undefined || userScore !== undefined) &&
        (questionScore !== 0 ||
          questionScore !== "0" ||
          questionScore !== "0.00") &&
        showAllScores !== false &&
        questionType !== "group" && (
          <>
            <div className="half-clearfix" />
            <div className="flex-container">
              <div className="flex-one side-padding">
                <div className="left-align">
                  {onReviewRequest  && (
                    <>
                      <Checkbox
                        addExtraId
                        options={{
                          enumOptions: [
                            {
                              value: toBeReviewed,
                              label: t("Select to send for review"),
                            },
                          ],
                        }}
                        onChange={handleReviewRequestToggle}
                        checked={toBeReviewed}
                      />

                      <div className="half-clearfix" />

                      <TextInput
                        inputType="text"
                        inputClass="review-comment"
                        name="reviewComment"
                        placeholder={t("Add comment here (optional)")}
                        value={reviewComment}
                        inputChange={(event, name) =>
                          onFieldChange(event.target.value, name)
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      {questionType === "group" &&
        subquestionsData &&
        subquestionsData.map((subque, index) =>
          getSubAnswerDisplay(subque, index + 1)
        )}

      {/* && submissionData?.result_settings?.show_answers */}

      {conditions &&
        conditions.length > 0 &&
        conditions
          .filter((show) => show.question_id === questionId)
          .map((show) => (
            <div className="question-conditions-container">
              {show.message && (
                <div className="condition-message">
                  <div className="half-clearfix" />
                  <div dangerouslySetInnerHTML={{ __html: show.message }} />
                </div>
              )}
              {show.buttons && (
                <div className="condition-buttons">
                  {show.buttons.map((button, index) => (
                    <SuggestionButton
                      key={index.toString()}
                      buttonLabel={button.label}
                      buttonAction={() =>
                        onConditionButtonClick(
                          button,
                          "quiz",
                          displayedQuestionsId,
                          submissionData
                        )
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
      <div className="half-clearfix" />
      <hr />
      <div className="half-clearfix" />
    </div>
  );
};

export default CorrectAnswerRecord;
