// Web Chat
export const HOME = "/";
export const NEW_HOME = "/new-chat";
export const OLD_HOME = "/old-chat";
export const QUIZ_REVIEW = "/quiz/submission-reviews/:submissionId";
export const NO_AGENT = "/no-agent-found";
export const TROPHY_ROOM = "/trophy-room";
export const BADGES = "/trophy-room/:badgeId";
export const NOTIFICATIONS = "/all-notifications";
export const USER_REPORT = "/reports";
export const SLS_CONFIRM = "/sls-confirmation";


// Login Widget
export const LOGIN_WIDGET_HOME = "/";
export const WIDGET_LOGIN_PAGE = "/widget-login";


//team chat
export const DISSCUSSION = "/team/discussion";
export const DISSCUSSION_DETAILS = "/team/discussion/details/:id";


//chat discussion
export const CHAT_DISSCUSSION = "/chat/discussion";
export const CHAT_DISSCUSSION_DETAILS = "/chat/discussion/details/:id";