// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import AgentClassService from './helpers/AgentClassService';
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if ( window.opener ) {
      if(window.location.search.includes("through") || window.location.search.includes("window-opener")) {
        const urlParams = new URLSearchParams(window.location.search);
        const agent = urlParams.get("agent");
        window.opener.document.redirectURI = agent ? agent : "null";
      }
      if(window.location.search.includes("keepAlive")) {
        window.opener.document.keepAlive = true;
      }
    }

    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
     
      setAuth0(auth0FromHook);
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=") && !window?.opener?.document?.keepAlive
      ) {
        if (window.SSO_PROVIDER === "oneauth") {
          // TODO: Compress redirection
          const isThorughPopup = localStorage.getItem("agentThroughAPI");
          const viewMode = localStorage.getItem("viewmode");
          const agent = isThorughPopup && isThorughPopup !== "null" && viewMode === "widget"  ? `&agent=${isThorughPopup}` : "";
          const viewModeParam = viewMode && viewMode !== "null"  ? `&viewmode=${viewMode}` : "";
          console.log(' selected agent ' + AgentClassService.getSelectedAgent());
          console.log(' selected class ' + AgentClassService.getClassUrlId());
          const selectedAgent = AgentClassService.getSelectedAgent();
          const selecetClass = AgentClassService.getClassUrlId();
          let popupParams = isThorughPopup && isThorughPopup !== "null" ? `?isLogin=popup` : "";
          if (selectedAgent) {
            popupParams =
              popupParams === ""
                ? `?agent=${selectedAgent}`
                : `&agent=${selectedAgent}`;
          }
          if (selecetClass) {
            popupParams =
              popupParams +
              (popupParams === ""
                ? `?class=${selecetClass}`
                : `&class=${selecetClass}`);
          }
          // const { appState } = await auth0FromHook.handleRedirectCallback();

          // console.log({appState})
          onRedirectCallback({
            targetUrl: "/" + popupParams
          });
        } else {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    setLoading(false);
    setIsAuthenticated(true);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
