import React, { useEffect, useRef, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import "./assets/css/bulma.css";
import history from "./history";
import AgentClassService from "./helpers/AgentClassService";
import LoadingAnimation from "./components/LoadingAnimation";
import {
  loginWidgetRoutes,
  teamsRoutes,
  webChatRoutes,
} from "./config/appRoutes";
import AuthCodeGenerator from "./containers/AuthCodeGenerator";
import WidgetLoginPage from "./containers/WidgetLoginPage";
import WidgetOAuthLogin from "./containers/WidgetOAuthLogin";
import { GlobalContext } from "./context/auth";
import LoginWidgetRoute from "./routes/LoginWidgetRoute";
import PrivateRoute from "./routes/PrivateRoute";

import "./App.css";
import { getUrlParameter } from "./helpers/formatFunctions";
import TeamRouteAuth from "./routes/TeamRouteAuth";
import { Auth0Provider } from "./react-auth0-spa";
import WindowOpener from "react-window-opener";
import PopupChatOpener from "./components/PopupChatOpener";

const urlParams = new URLSearchParams(window.location.search);
const login = urlParams.get("login");
const chatSelectedAgent = AgentClassService.getSelectedAgent();
const loginWithPopup = login === "popup" && !chatSelectedAgent;
const agent = urlParams.get("agent");
const through = urlParams.get("through");

const App = (props) => {
  const defaultDisclaimer =
    "Please note that the information you receive from now on is outside of the set knowledge base and the system may occasionally generate incorrect or misleading information and produce offensive or biased content.";
  const [authTokens, setAuthTokens] = useState(
    JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : false
  );
  const [showAccessDenied, setShowAccessDenied] = useState(false);
  const [showAgentSelection, setShowAgentSelection] = useState(false);
  const [showOrgSelection, setShowOrgSelection] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [authCode, setAuthCode] = useState("");
  const [bubbleIcon, setBubbleIcon] = useState("");
  const [agentLanguage, setAgentLanguage] = useState("");
  const [agentProfileImage, setAgentProfileImage] = useState("");
  const [agentDisclaimer, setAgentDisclaimer] = useState({
    defaultDisclaimer: defaultDisclaimer,
  });
  const [userFullName, setUserFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [loginThroughProps, setLoginThroughProps] = useState(null);
  const chatSelectedAgent = AgentClassService.getSelectedAgent();
  const [showChangeOrganisation, setShowChangeOrganisation] = useState(false);
  const [allOrganisations, setAllOrganisations] = useState([]);

  const setTokens = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    setAuthTokens(data);
  };

  useEffect(() => {
    const executeSSO = async (container) => {
      try {
        let allParams = getUrlParameter(window.location.search.split("?")[1]);
        const login = allParams.login;
        if (login) {
          const chatSelectedAgent = AgentClassService.getSelectedAgent();
          const loginWithPopup = login === "popup" && !chatSelectedAgent;
          const isThorughPopup = localStorage.getItem("agentThroughAPI");
          setLoginThroughProps(loginWithPopup);
        } else {
          setLoginThroughProps(false);
        }

        const code = allParams.t;
        const expires_in = allParams.e;
        const agent = allParams.agent;
        const agentClass = allParams.class;
        // Added this so AUTH widget will work
        if (!agent) {
          return;
        }

        if (code) {
          await localStorage.clear();
          AgentClassService.clear();
          await setAuthTokens(null);
        }

        if (code && code.trim().length) {
          localStorage.setItem("widget_auth_token", code);
          localStorage.setItem("agent", agent);
          localStorage.setItem("chat_selected_agent", agent);
          AgentClassService.saveToLocalStorage();
          AgentClassService.setSelectedAgent(agent);
          setAuthTokens({
            access_token: code,
            expires_in: expires_in,
            scope: "openid profile email",
            token_type: "Bearer",
          });
          if (container && container[0]) {
            container[0].classList.add("wmi");
          }
          history.push(`/?widgettype=SSO`);
        }
      } catch (e) {
        console.log("e", e);
      }
    };

    const container = document.getElementsByClassName(
      "widget-chat-page-container"
    );
    if (container.length) {
      if (container[0].classList.contains("wmi")) {
        container[0].classList.remove("wmi");
      }
    }
    executeSSO(container);
  }, []);

  const onRedirectCallback = (appState) => {
    if (window.opener && window?.opener?.document?.redirectURI) {
      setTimeout(function(){
        window.close();
      }, 1000);
      // window.close();
      window.opener.onSuccess(
        window?.opener?.document?.redirectURI
          ? window.opener.document.redirectURI
          : ""
      );
      return;
    }

    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  const childResponse = async (err, res) => {
    if (err) {
      console.log("err", err);
    }
    const throughAPI = res !== "null" ? `/?agent=${res}&through=admin-portal` : "/";
    await localStorage.setItem("agentThroughAPI", res);
    window.location.href = throughAPI;
  };

  if (window.location.search.includes("login=popup")) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "250px",
          height: "100px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <WindowOpener
          width="600"
          height="600"
          url={`/${
            props.login && props.login === "popup" && !props.agent && !props.through ?  `?login=window-opener` : props.agent && props.through
              ? `?agent=${props.agent}&through=${props.through}`
              : ""
          }`}
          bridge={childResponse}
        >
          {" "}
          <div
            style={{
              backgroundColor: "rgb(9, 35, 230)",
              cursor: "pointer",
              border: "0",
              padding: "14px",
              display: "block",
              boxSizing: "border-box",
              width: "100%",
              overflow: "hidden",
              borderRadius: "5px",
              transition: "0.2s ease-in-out",
              color: "#fff",
              letterSpacing: "1px",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            Continue to chat
          </div>
        </WindowOpener>
      </div>
    );
  }


  // FOR SHOW LOGIN BUTTON
  if(window.location?.pathname.includes("/popup/")){
    localStorage.clear()
    AgentClassService.clear();
    return (
      <Router history={history}>
        {/* Other routes... */}
        <Route path="/popup/:agent" component={PopupChatOpener} />
      </Router>
    );
  }

  return window.location.search.includes("login=popup") ? (
    <>
    </>
  ) : (
    <>
      <Auth0Provider
        domain={window.SSO_DOMAIN}
        client_id={window.SSO_CLIENTID}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={window.SSO_AUDIENCE}
      >
        <GlobalContext.Provider
          value={{
            authTokens,
            setAuthTokens: setTokens,
            showAccessDenied,
            setShowAccessDenied,
            showAgentSelection,
            setShowAgentSelection,
            selectedClass,
            setSelectedClass,
            authCode,
            setAuthCode,
            bubbleIcon,
            setBubbleIcon,
            agentLanguage,
            setAgentLanguage,
            agentProfileImage,
            setAgentProfileImage,
            agentDisclaimer,
            setAgentDisclaimer,
            userFullName,
            setUserFullName,
            userRole,
            setUserRole,
            showChangeOrganisation,
            setShowChangeOrganisation,
            allOrganisations,
            setAllOrganisations,
            showOrgSelection,
            setShowOrgSelection
          }}
        >
          <Router history={history}>
            <React.Suspense fallback={LoadingAnimation}>
              {process.env && process.env.REACT_APP_TYPE === "web_chat" && (
                <Switch>
                  {webChatRoutes.map((route, index) => {
                    return (
                      <PrivateRoute exact {...route} isAuth={authTokens} />
                    );
                  })}
                  <Route path="/team" component={TeamRouteAuth} />
                </Switch>
              )}

              {/* Updated routes */}
              {process.env && process.env.REACT_APP_TYPE === "login_widget" && (
                <Switch>
                  {loginWidgetRoutes.map((route, index) => {
                    return <LoginWidgetRoute exact {...route} />;
                  })}
                  <Route
                    exact
                    component={WidgetLoginPage}
                    path="/widget-login"
                  />
                  <Route
                    exact
                    component={AuthCodeGenerator}
                    path="/auth-code"
                  />
                  <Route
                    exact
                    component={WidgetOAuthLogin}
                    path="/auth/:widgetInstanceId"
                  />
                </Switch>
              )}
            </React.Suspense>
          </Router>
        </GlobalContext.Provider>
      </Auth0Provider>
    </>
  );
};

export default App;
