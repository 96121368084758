import React, { useEffect, useRef, useState } from "react";

import TextInput from "../TextInput";
import Button from "../Button";
import "./style.css";
import { OverlayTrigger, Popover, Image, Tooltip } from "react-bootstrap";
import tooltipImg from "../../assets/images/svg/tooltip-img.svg";
import Modal from "../Modal";

import { useTranslation } from "react-i18next";

const MultiDocumentPreview = (props) => {
  const { t } = useTranslation("translation");
  const ref = useRef();
  const [selectedDoc, setSelectedDoc] = useState("");
  const [blocks, setBlocks] = useState({});
  const [cachedBlocks, setCachedBlocks] = useState({});
  const [showAdditionalRef, setShowAdditionalRef] = useState(false);
  const [showAddRefModal, setShowAddRefModal] = useState(false);

  useEffect(() => {
    setShowAdditionalRef(false);
  }, [props.keyword]);

  const firstRef =
    props?.docPreviews.length <= 0 ? null : props?.docPreviews[0];

  const getBlock = async ({ id, text_block_id, docId }) => {
    let block;
    if (cachedBlocks[text_block_id]) {
      block = cachedBlocks[text_block_id];
    } else {
      block = await props.updateTextBlock(docId, text_block_id);
    }
    const newBlocks = { ...blocks };
    newBlocks[id] = block;
    setBlocks(() => newBlocks);
    const newCachedBlocks = { ...cachedBlocks };
    newCachedBlocks[text_block_id] = block;
    setCachedBlocks(() => newCachedBlocks);
  };

  const selectDoc = async ({ id }) => {
    setSelectedDoc(id);
    if (!blocks[id] && id) {
      const block = await props.getTextBlock(props.replyId, id);
      const newBlocks = { ...blocks };
      newBlocks[id] = block;
      setBlocks(newBlocks);
      const newCachedBlocks = { ...cachedBlocks };
      newCachedBlocks[id] = block;
      setCachedBlocks(() => newCachedBlocks);
    }
  };

  return (
    <>
      {props?.docPreviews.length > 1 && showAddRefModal && props.isMobile && (
        <div className="chat-modal additional-ref-modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title mb-0">
                {t("Additional References")}
              </div>
              <button
                className="close mr-0 position-static px-0"
                onClick={() => {
                  setShowAddRefModal(false);
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body p-3">
              {t(
                "The answer may be supported by multiple sources. Click the 'View' icon to explore additional references used in the answer."
              )}
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-outline-primary m-0 py-1"
                onClick={() => {
                  setShowAddRefModal(false);
                }}
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </div>
      )}
      <>
        <div className="option-question-container">
          {props.onClickBackButton && (
            <div
              className="back-button-wrapper"
              onClick={props.onClickBackButton}
            >
              <button className="header-back-btn">
                <i className="icon-left-chevron" />
              </button>
              <div className="doc-header-heading">{t("Go back to chat")}</div>
            </div>
          )}
          <div className="half-clearfix" />
          <div className="custom-scrollbar sidebar-scroll-content">
            <div className="page-subheading">{t("Answer")}</div>
            <div className="answer-textarea">
              <TextInput
                required
                useTextArea
                inputType="text"
                disabled={true}
                inputClass="input-nf custom-scrollbar"
                placeholder={t("Type keyword(s) here")}
                value={props.keyword}
              ></TextInput>
            </div>

            <div className="half-clearfix" />
            <>
              <div className="page-subheading">{t("Main Reference")}</div>
              {firstRef && (
                <div>
                  <div
                    key={firstRef.id}
                    className="content-preview-wrapper mb-2"
                  >
                    <div
                      className="doc-title"
                      onClick={() => selectDoc(firstRef)}
                    >
                      <span className="doc-name">{`${firstRef?.document?.name}`}</span>
                      <span>{`, ${firstRef.page_number}`}</span>
                    </div>
                    {selectedDoc === firstRef.id && (
                      <>
                        <div className="d-flex align-items-center justify-content-between pb-5">
                          <div>{t("More Information ")}</div>
                          <div
                            className="close"
                            onClick={() => setSelectedDoc("")}
                          >
                            x
                          </div>
                        </div>
                        <div className="content-preview-file-content">
                          <pre
                            className="conversation"
                            dangerouslySetInnerHTML={{
                              __html: blocks?.[firstRef?.id]?.text_block?.text,
                            }}
                          ></pre>
                        </div>
                        <div className="preview-button-wrapper-down">
                          <Button
                            isDisabled={
                              !blocks?.[firstRef?.id]?.previous_text_block_id
                            }
                            buttonClass="preview-button-up mr-2"
                            onClick={() => {
                              getBlock({
                                id: firstRef?.id,
                                text_block_id:
                                  blocks?.[firstRef?.id]
                                    ?.previous_text_block_id,
                                docId: firstRef?.document_id,
                              });
                            }}
                          >
                            <svg
                              aria-hidden="true"
                              viewBox="0 0 16 16"
                              version="1.1"
                              data-view-component="true"
                              height="16"
                              width="16"
                              className="octicon octicon-fold-up"
                            >
                              <path d="M7.823 1.677L4.927 4.573A.25.25 0 005.104 5H7.25v3.236a.75.75 0 101.5 0V5h2.146a.25.25 0 00.177-.427L8.177 1.677a.25.25 0 00-.354 0zM13.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zm-3.75.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM7.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM4 11.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM1.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5z"></path>
                            </svg>
                          </Button>
                          <Button
                            buttonClass="preview-button-down"
                            isDisabled={
                              !blocks?.[firstRef?.id]?.next_text_block_id
                            }
                            onClick={() => {
                              getBlock({
                                id: firstRef?.id,
                                text_block_id:
                                  blocks?.[firstRef?.id]?.next_text_block_id,
                                docId: firstRef?.document_id,
                              });
                            }}
                          >
                            <svg
                              aria-hidden="true"
                              viewBox="0 0 16 16"
                              version="1.1"
                              data-view-component="true"
                              height="16"
                              width="16"
                              className="octicon octicon-fold-down"
                            >
                              <path d="M8.177 14.323l2.896-2.896a.25.25 0 00-.177-.427H8.75V7.764a.75.75 0 10-1.5 0V11H5.104a.25.25 0 00-.177.427l2.896 2.896a.25.25 0 00.354 0zM2.25 5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5zM6 4.25a.75.75 0 01-.75.75h-.5a.75.75 0 010-1.5h.5a.75.75 0 01.75.75zM8.25 5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5zM12 4.25a.75.75 0 01-.75.75h-.5a.75.75 0 010-1.5h.5a.75.75 0 01.75.75zm2.25.75a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5z"></path>
                            </svg>
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
            {props?.docPreviews.length > 1 && (
              <>
                <div className="half-clearfix" />
                <div className="page-subheading additional-reference">
                  <div className="">
                    {t("Additional References")}
                    {
                      <div className="hover-text ml-2">
                        {!props.isMobile && (
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="right"
                            overlay={(props) => (
                              <Popover
                                className="additional-ref"
                                id="popover-basic-"
                                {...props}
                              >
                                <Popover.Content>
                                  {t(
                                    "The answer may be supported by multiple sources. Click the 'View' icon to explore additional references used in the answer."
                                  )}
                                </Popover.Content>
                              </Popover>
                            )}
                          >
                            <Image
                              ref={ref}
                              src={tooltipImg}
                              className="ml-2"
                              alt="Info"
                            />
                          </OverlayTrigger>
                        )}
                        {props.isMobile && (
                          <Image
                            src={tooltipImg}
                            className="ml-2"
                            alt="Info"
                            onClick={() => setShowAddRefModal(true)}
                          />
                        )}
                      </div>
                    }
                  </div>
                  {!showAdditionalRef && (
                    <div
                      className="view-btn"
                      onClick={() => {
                        setShowAdditionalRef(true);
                      }}
                    >
                      {t("View")}
                    </div>
                  )}
                </div>

                {showAdditionalRef && (
                  <div>
                    {props?.docPreviews.slice(1).map((doc) => (
                      <div
                        key={doc.id}
                        className="content-preview-wrapper mb-2"
                      >
                        <div
                          className="doc-title"
                          onClick={() => selectDoc(doc)}
                        >
                          <span className="doc-name">{`${doc?.document?.name}`}</span>
                          <span>{`, ${doc.page_number}`}</span>
                        </div>
                        {selectedDoc === doc.id && (
                          <>
                            <div className="d-flex align-items-center justify-content-between pb-5">
                              <div>{t("More Information ")}</div>
                              <div
                                className="close"
                                onClick={() => setSelectedDoc("")}
                              >
                                x
                              </div>
                            </div>
                            <div className="content-preview-file-content">
                              <pre
                                className="conversation"
                                dangerouslySetInnerHTML={{
                                  __html: blocks?.[doc?.id]?.text_block?.text,
                                }}
                              ></pre>
                            </div>
                            <div className="preview-button-wrapper-down">
                              <Button
                                isDisabled={
                                  !blocks?.[doc?.id]?.previous_text_block_id
                                }
                                buttonClass="preview-button-up mr-2"
                                onClick={() => {
                                  getBlock({
                                    id: doc?.id,
                                    text_block_id:
                                      blocks?.[doc?.id]?.previous_text_block_id,
                                    docId: doc?.document_id,
                                  });
                                }}
                              >
                                <svg
                                  aria-hidden="true"
                                  viewBox="0 0 16 16"
                                  version="1.1"
                                  data-view-component="true"
                                  height="16"
                                  width="16"
                                  className="octicon octicon-fold-up"
                                >
                                  <path d="M7.823 1.677L4.927 4.573A.25.25 0 005.104 5H7.25v3.236a.75.75 0 101.5 0V5h2.146a.25.25 0 00.177-.427L8.177 1.677a.25.25 0 00-.354 0zM13.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zm-3.75.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM7.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM4 11.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM1.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5z"></path>
                                </svg>
                              </Button>
                              <Button
                                buttonClass="preview-button-down"
                                isDisabled={
                                  !blocks?.[doc?.id]?.next_text_block_id
                                }
                                onClick={() => {
                                  getBlock({
                                    id: doc?.id,
                                    text_block_id:
                                      blocks?.[doc?.id]?.next_text_block_id,
                                    docId: doc?.document_id,
                                  });
                                }}
                              >
                                <svg
                                  aria-hidden="true"
                                  viewBox="0 0 16 16"
                                  version="1.1"
                                  data-view-component="true"
                                  height="16"
                                  width="16"
                                  className="octicon octicon-fold-down"
                                >
                                  <path d="M8.177 14.323l2.896-2.896a.25.25 0 00-.177-.427H8.75V7.764a.75.75 0 10-1.5 0V11H5.104a.25.25 0 00-.177.427l2.896 2.896a.25.25 0 00.354 0zM2.25 5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5zM6 4.25a.75.75 0 01-.75.75h-.5a.75.75 0 010-1.5h.5a.75.75 0 01.75.75zM8.25 5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5zM12 4.25a.75.75 0 01-.75.75h-.5a.75.75 0 010-1.5h.5a.75.75 0 01.75.75zm2.25.75a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5z"></path>
                                </svg>
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default MultiDocumentPreview;
