import React, { useState, useEffect } from "react";
import IconLabelButton from "../../components/IconLabelButton";
import { getLangValue } from "../../components/Internationalisation/i18n";
import { useAuth } from "../../context/auth";

import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";

import "./style.css";

import { useTranslation } from "react-i18next";

import backimg from "../../assets/images/new-images/chevron-left-blk.svg";

import downarrowblk from "../../assets/images/blue-caret.svg";
import chevblue from "../../assets/images/new-images/chevron-right-blue.svg";
import { Card, Image } from "react-bootstrap";
import ReactSelect from "react-select";
import * as _ from "lodash";

const AgentSelection = (props) => {
  const { t } = useTranslation("translation");
  const { setAgentLanguage } = useAuth();
  const [chooseClicked, setChooseClicked] = useState(false);
  const [query, setQuery] = useState("");
  const [agentOrder, setAgentOrder] = useState({
    value: null,
    label: t("By Agent Name"),
  });
  const makeApiCall = useFetch();

  const [allAgents, setAllAgents] = useState([]);
  const [currentOrganisation, setCurrentOrganisation] = useState(null);

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = () => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agents", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setAllAgents(json.data.agents);
        }
      });

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/organisations/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setCurrentOrganisation(json.data.organisation || null);
        }
      });
  };

  const onAgentHandler = (allAgents) => {
    let temp = [...allAgents];
    temp = _.orderBy(
      temp,
      agentOrder.value === null
        ? [(u) => u.name.toLowerCase()]
        : [(u) => new Date(u.updated_at)],
      "asc"
    );

    if (temp && temp.length > 0) {
      if (query && query.trim().length > 0) {
        temp = temp.filter(
          (e) =>
            (e.name || "").toLowerCase().includes(query.toLowerCase()) ||
            (e.description || "").toLowerCase().includes(query.toLowerCase())
        );
      }
    }

    return temp;
  };

  const whileChange = (agentId, langCode) => {
    setChooseClicked(true);
    setAgentLanguage(getLangValue(langCode));
    props.onAgentChange(agentId);
  };

  // const refreshPageData = () => {
  //   //code to refresh page data
  // };

  // const onChangeAgent = (agentId) => {
  //   setSelectedAgent(agentId);
  //   localStorage.setItem("selectedAgent", agentId);
  //   window.location.reload();
  // };

  const getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <>
      <section className="mt-5 choose-organisation">
        <div className="container-md">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex align-items-center mb-4">
                {props.selectedAgent ? (
                  <>
                    <button
                      className="btn p-0 mr-3"
                      onClick={props.hideAgentSelection}
                    >
                      <Image src={backimg} />
                    </button>

                    <h1 className="font-weight-bold mb-0">
                      {currentOrganisation?.name}
                    </h1>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="font-18-px font-600 mb-5">
                {t("Select the agent which you would like to access")}
              </div>
              <div className="web-links justify-content-between d-flex align-items-center mw-100 mb-5">
                <input
                  type="text"
                  className="form-control h-auto"
                  placeholder="Search agent"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <div className="doc-select-dropdown ml-3">
                  <ReactSelect
                    className="select-sort select-target select-blue shadow-none radius-8"
                    classNamePrefix="select-target-prefix"
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => (
                        <img src={downarrowblk} alt="dropdown arrow" />
                      ),
                    }}
                    options={[
                      {
                        value: null,
                        label: t("By Agent Name"),
                      },
                      {
                        value: "date",
                        label: t("Date Created"),
                      },
                    ]}
                    onChange={(e) => {
                      setAgentOrder(e);
                    }}
                    value={agentOrder}
                  />
                </div>
              </div>
              <div className="accordion-viewby mt-3 scroll-wrap accordion-ag">
                {allAgents &&
                  allAgents.length > 0 &&
                  onAgentHandler(allAgents).map((agent, index) => {
                    return (
                      <Card className="mb-3">
                        <Card.Header className="d-flex px-4 py-2 justify-content-between align-items-center">
                          <span
                            className={`d-flex font-weight-bold align-items-center`}
                          >
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#CDD5EF",
                                textAlign: "center",
                                lineHeight: "40px",
                                overflow: "hidden",
                              }}
                            >
                              <Image
                                src={agent?.settings?.chat_bot_avatar}
                                alt={getInitials(agent.name)}
                              />
                            </div>
                            <div
                              className="pl-3"
                              style={{
                                width: "calc(100% - 40px)",
                              }}
                            >
                              {props.selectedAgent === agent._id && (
                                <p className="clr-pink font-weight-bold w-100 m-0">
                                  {t("Current Selected")}
                                </p>
                              )}
                              <div className="agent-name">{agent.name}</div>
                              <div className="agent-description">
                                {agent.description}
                              </div>
                            </div>
                          </span>
                          {props.selectedAgent !== agent._id && (
                            <button
                              onClick={
                                chooseClicked
                                  ? null
                                  : () =>
                                      whileChange(
                                        agent._id,
                                        agent?.language_code
                                      )
                              }
                              className="d-flex btn pr-4 align-items-center "
                            >
                              {t("Choose")}
                              <img
                                className="pl-3"
                                src={chevblue}
                                alt="cheveron-blue"
                              />
                            </button>
                          )}
                        </Card.Header>
                      </Card>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="agent-select-container d-none">
        <div className="agent-select-header">
          {props.selectedAgent ? (
            <IconLabelButton
              showIcon
              iconClass="icon-left-chevron"
              onClick={props.hideAgentSelection}
              buttonLabel={t("Go to chat")}
            />
          ) : (
            <div />
          )}
        </div>
        <div className="agent-select-heading">
          {t("Choose an agent to chat with.")}
        </div>
        <div className="agent-options">
          {onAgentHandler(allAgents).map((agent) => (
            <div
              className={
                chooseClicked
                  ? "agent-option-item agent-option-item-disabled"
                  : props.selectedAgent === agent._id
                  ? "agent-option-item agent-option-item-active"
                  : "agent-option-item"
              }
              onClick={
                chooseClicked
                  ? null
                  : () => whileChange(agent._id, agent?.language_code)
              }
            >
              <div className="agent-name">{agent.name}</div>
              <div className="agent-description">{agent.description}</div>
              <button
                className={
                  chooseClicked
                    ? "agent-option-btn agent-option-btn-disabled"
                    : props.selectedAgent === agent._id
                    ? "agent-option-btn agent-option-btn-active"
                    : "agent-option-btn"
                }
              >
                {props.selectedAgent === agent._id ? t("Active") : t("Choose")}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AgentSelection;
